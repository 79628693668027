.backdrop {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5)
}

.loader {
  width: 200px;
  height: 200px;
}