@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./assets/fonts/Tajawal/Tajawal-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  line-height: initial;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./assets/fonts/Tajawal/Tajawal-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  line-height: initial;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./assets/fonts/Tajawal/Tajawal-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  line-height: initial;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./assets/fonts/Tajawal/Tajawal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  line-height: initial;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./assets/fonts/Tajawal/Tajawal-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  line-height: initial;
}

@font-face {
  font-family: 'Tajawal';
  src: url('./assets/fonts/Tajawal/Tajawal-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  line-height: initial;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ece4d5;
  font-family: 'Roboto', sans-serif;
}

.custom-placeholder::placeholder {
  color: #776e65 !important;
  opacity: 1;
}

.pac-container {
  z-index: 2000 !important;
}

::-webkit-scrollbar {
  display: none;
}
